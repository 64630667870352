import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { FormControl } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import axiosInstance from "src/utils/axios";
import useLocales from "src/hooks/useLocales";
import useGetSubChapter from "../../../sub-chapter/hook/useGetSubChapter";

const VideoEditDialog = ({ open, onClose, title, methods, fetchVideos }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async ({  url, id, sub_chapter_id }) => {
    const reqData = new FormData();
    
    reqData.append("url", url);
    reqData.append("sub_chapter_id", sub_chapter_id);
    reqData.append("_method", "PUT");

    try {
      const { status, data: resData } = await axiosInstance.post(
        `api/admin/sub-chapter-video/${id}`,
        reqData
      );
      if (status === 200) {
        fetchVideos();
        onClose();
        enqueueSnackbar(resData.message);
      }
    } catch (err) {}
  };

  const { state } = useGetSubChapter();
  const { data } = state;

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="tool-doc"
    >
      <DialogTitle id="tool-doc">{title}</DialogTitle>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
           

            <Box mt={3}>
              <FormControl fullWidth>
                <RHFTextField
                  name="url"
                  id="url"
                  label={translate("adminTools.videos.videoURL")}
                  variant="outlined"
                />
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth>
                <RHFSelect name="sub_chapter_id" label="Sub Chapter">
                  <option value="" />
                  {data.map(({ id, name }) => (
                    <option value={id}> {name}</option>
                  ))}
                </RHFSelect>
              </FormControl>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            autoFocus
            variant="contained"
            // startIcon={<Iconify icon={"bi:upload"} />}
          >
            {translate("adminTools.videos.update")}
          </Button>
          <Button onClick={onClose} autoFocus color="error">
            {translate("adminTools.videos.close")}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default VideoEditDialog;
