import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Card,
  Divider,
  Grid,
  MenuItem,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import PaginationButtons from "src/components/pagination";
import TableMenu from "src/components/tableMenu";
import useLocales from "src/hooks/useLocales";
import useFetchDocuments from "../hooks/useFetchDocuments";
import DataList from "./dataList";
import DeleteDialog from "./deleteDialog";
import { AddDialog, EditDialog } from "./updateEditDialog";
import { BodyRow } from "src/pages/admin/reports/components";
import EmptyTable from "src/components/emptyTable";
import DataHandlerTable from "src/components/data-handler/table";
import Map from "src/components/map";
import Ternary from "src/components/ternary";

const headers = [
  "adminTools.documents.no",
  "adminTools.documents.download",
  "adminTools.documents.created",
  "adminTools.documents.action",
];

const MainSection = () => {
  const { translate } = useLocales();

  const { state, documents, fetchDocuments, rowStart, ...rest } =
    useFetchDocuments();
  const { data, ...dataProps } = state;

  const [selectedId, setSelectedId] = useState(null);

  const [openMenu, setOpenMenuActions] = useState(false);
  const handleOpenMenu = (id) => (event) => {
    setSelectedId(id);
    setOpenMenuActions(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(false);
  };

  const [openUpload, setOpenUpload] = useState(false);
  const handleOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    handleCloseMenu();
    setOpenEdit(true);
  };

  const handleCloseEdit = () => setOpenEdit(false);

  return (
    <>
      <Grid item sm={12} mr={1} mb={1} mt={1}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            size="small"
            startIcon={<Iconify icon={"carbon:add"} />}
            onClick={handleOpenUpload}
            name="add"
          >
            {translate("adminTools.documents.fileUpload")}
          </Button>
        </Box>
      </Grid>
      <Card>
        <Grid container></Grid>

        <Scrollbar>
          <DataHandlerTable
            name="documents"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(documents, i) => (
                <DataList
                  handleOpenMenu={handleOpenMenu}
                  documents={documents}
                  rowNumber={rowStart + i}
                />
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
        <Divider />
      </Card>

      <TableMenu open={openMenu} onClose={handleCloseMenu}>
        <MenuItem
          sx={{ color: "default.main" }}
          onClick={handleOpenEdit}
          name="edit"
        >
          <Iconify icon={"akar-icons:edit"} />
          {translate("adminTools.documents.edit")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "error.main" }}
          onClick={handleOpenDelete}
          name="delete"
        >
          <Iconify icon={"eva:trash-2-outline"} />
          {translate("adminTools.documents.delete")}
        </MenuItem>
      </TableMenu>

      <AddDialog
        open={openUpload}
        onClose={handleCloseUpload}
        reload={fetchDocuments}
      />

      <EditDialog
        open={openEdit}
        onClose={handleCloseEdit}
        reload={fetchDocuments}
        selectedId={selectedId}
      />

      <DeleteDialog
        open={openDelete}
        deleteId={selectedId}
        onClose={handleCloseDelete}
        reload={fetchDocuments}
      />

      <Ternary
        when={!dataProps.isArrayEmpty}
        then={<PaginationButtons {...rest} />}
      />
    </>
  );
};

export default MainSection;
