import { Box, Card } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import PaginationButtons from "src/components/pagination";
import usePagination from "src/components/pagination/usePagination";
import { PATH_DASHBOARD } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import AddDialog from "./videos/addDialog";
import VideoList from "./videos/videoList";
import useLocales from "src/hooks/useLocales";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import DataHandlerTable from "src/components/data-handler/table";
import Ternary from "src/components/ternary";

const Videos = () => {
  const { translate } = useLocales();
  const [videos, setVideos] = useState([]);
  const [editVideo, setEditVideo] = useState(null);
  const [addVideo, setAddVideo] = useState(null);

  const { count, onChange, page, seed } = usePagination();
  const [state, actions] = useDataHandler();
  const { data, ...dataProps } = state;
  const fetchVideo = async (page = 1) => {
    actions.loading();
    try {
      const { data } = await axiosInstance.get(
        `/api/admin/sub-chapter-video?page=${page}`
      );
      const { status, data: videos } = data;
      if (status) {
        const { last_page, data: list } = videos;
        seed(last_page);
        setVideos(list);
        actions.success(list);
        return;
      }
      actions.success();
    } catch (err) {
      actions.error();
      console.error(err);
    }
  };

  useEffect(() => {
    fetchVideo(page);
  }, [page]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2, display: "flex", justifyContent: "right" }}>
        <AddDialog fetchVideo={fetchVideo} addVideo={addVideo} />
      </Box>
      <Card>
        <DataHandlerTable dataProps={{ ...dataProps }}>
          <VideoList
            videos={videos}
            fetchVideo={fetchVideo}
            setEditVideo={setEditVideo}
          />
        </DataHandlerTable>
      </Card>
      <Ternary
        when={!dataProps.isArrayEmpty}
        then={
          <PaginationButtons count={count} onChange={onChange} page={page} />
        }
      />
    </Box>
  );
};

export default Videos;
