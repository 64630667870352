import { lazy } from "react";
import { Navigate } from "react-router";
import Documents from "src/pages/admin/course/materials/subPages/documents";
import Videos from "src/pages/admin/course/materials/subPages/videos.js";
import Loadable from "src/routes/Loadable";

const Course = Loadable(
  lazy(() => import("src/pages/admin/course/course/index"))
);
const Chapter = Loadable(
  lazy(() => import("src/pages/admin/course/chapter/index"))
);

const SubChapter = Loadable(
  lazy(() => import("src/pages/admin/course/sub-chapter/index"))
);
const Materials = Loadable(
  lazy(() => import("src/pages/admin/course/materials/index"))
);

const course = [
  {
    path: "course",
    children: [
      { element: <Navigate to="course" />, index: true },
      { path: "course", element: <Course /> },
      { path: "chapter", element: <Chapter /> },
      { path: "subchapter", element: <SubChapter /> },
      {
        path: "materials",
        element: <Materials />,
        children: [
          {
            index: true,
            element: <Navigate to="documents" />,
          },

          {
            path: "documents",
            element: <Documents />,
          },

          {
            path: "videos",
            element: <Videos />,
          },
        ],
      },
    ],
  },
];
export default course;
