import { lazy } from "react";
import Loadable from "../Loadable";

const Course = Loadable(lazy(() => import("src/pages/user/course/index")));
const Chapter = Loadable(
  lazy(() => import("src/pages/user/course/components/chapter/index"))
);
const SubChapters = Loadable(
  lazy(() => import("src/pages/user/course/components/subChapter/index"))
);

const course = {
  path: "course",
  children: [
    { index: true, element: <Course /> },
    {
      path: "chapter/:id",
      element: <Chapter />,
      children: [{ path: ":subId", element: <SubChapters /> }],
    },
  ],
};
export default course;
