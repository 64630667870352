import Page from "src/components/Page";
import RootStyle from "../shared/rootStyle";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import MainSection from "./components/MainSection";
import useQueryParams from "src/hooks/useQueryParams";
import { useSnackbar } from "notistack";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { queryObject } = useQueryParams();
  if (queryObject.message) {
    enqueueSnackbar(queryObject.message);
  }
  return (
    <Page title="Login">
      <RootStyle>
        <Header />
        <ImageCard />
        <MainSection />
      </RootStyle>
    </Page>
  );
};

export default Login;
