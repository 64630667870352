import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import EmptyContent from "src/components/EmptyContent";
import Oops from "src/images/oops.png";
import Ternary from "../ternary";
import Iconify from "../Iconify";
import EmptyMail from "src/pages/admin/communications/Mail/subPages/MailList/components/emptyMail";

const EmptyTable = ({ error = false, isMail }) => {
  const { palette } = useTheme();

  return (
    <Box>
      <Ternary
        when={error}
        then={
          <Box sx={{ p: 4 }}>
            <Box
              sx={{ justifyContent: "center", display: "flex", mt: 4, mb: 2 }}
            >
              {/* <img src={Oops} width="70" /> */}
              <Box
                style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "16px",
                  backgroundColor: "#ffeeef",
                  textAlign: "center",
                  lineHeight: "80px",
                  display: "flex",
                  margin: "15px auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                 
                <Iconify
                  icon="clarity:info-solid"
                  style={{ color: "#ff9595", fontSize: "45px" }}
                />
              </Box>
            </Box>
            <Typography
              variant="h3"
              sx={{ color: "#ff6767", textAlign: "center" }}
            >
              Oops !!!
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", color: "#637381" }}
            >
              Something went wrong. Please try again later
            </Typography>
          </Box>
        }
        otherwise={
          <Ternary
            when={isMail}
            then={<EmptyMail />}
            otherwise={
              <EmptyContent
                title={
                  <Typography variant="subtitle2">No Data Available</Typography>
                }
                sx={{
                  "& span.MuiBox-root": { height: 160 },
                  color: "#b2b2b2",
                }}
              />
            }
          />
        }
      />
    </Box>
  );
};

export default EmptyTable;
