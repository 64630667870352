import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase } from "change-case";
import DataHandlerTable from "src/components/data-handler/table";
import EmptyTable from "src/components/emptyTable";
import Map from "src/components/map";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { BodyRow } from "src/pages/admin/reports/components";

const headers = [
  "userFinancial.fundTransfer.no",
  "userFinancial.fundTransfer.toUser",
  "userFinancial.fundTransfer.paymentType",
  "userFinancial.fundTransfer.walletType",
  "userFinancial.fundTransfer.amount",
  "userFinancial.fundTransfer.date",
  "userFinancial.fundTransfer.note",
  "userFinancial.fundTransfer.paymentStatus",
];

const DataList = ({ state, rowStart, ...rest }) => {
  const { translate } = useLocales();
  const { data, ...dataProps } = state;

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable
            name="faq-table"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(item, i) => (
                <>
                  <TableRow>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item?.user?.username}</TableCell>
                    <TableCell>{capitalCase(item.type)}</TableCell>
                    <TableCell>{capitalCase(item.wallet_type)}</TableCell>
                    <TableCell>{item.total_amount}</TableCell>
                    <TableCell>
                      {new Date(item.created_at)?.toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell>{item.payment_status}</TableCell>
                  </TableRow>
                </>
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>

      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
