import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "src/components/Password";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import ErrorBanner from "./components/errorBanner";
import useLogin from "./hooks/useLogin";
import { useTheme } from "@emotion/react";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { setSession } from "src/utils/jwt";
import axiosInstance from "src/utils/axios";
import Iconify from "src/components/Iconify";
const LoginForm = () => {
  // const { methods, onSubmit } = useLogin();
  const { palette } = useTheme();
  const { login, getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      // .email("Email must be a valid email address")
      .required("Email or Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const [tfaError, setTfaError] = useState(null);
  const [openOtp, setOpenOtp] = useState(false);
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState(null);
  const handleClose = () => {
    setOpenOtp(false);
  };
  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    const {
      status,
      data: res,
      message,
      secret,
    } = await login(data.email, data.password, data.remember);
    if (status) {
      getUser();
      enqueueSnackbar(`Welcome ${res}`);
      reset();
    } else if (secret) {
      setSecret(secret);
      setOpenOtp(true);
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };
  useEffect(() => {
    if (otp === "") {
      setTfaError(null);
    }
  }, [otp]);
  const TfaLogin = async () => {
    const reqData = new FormData();
    const [email, password] = getValues(["email", "password"]);
    reqData.append("email", email);
    reqData.append("password", password);
    reqData.append("key", secret);
    reqData.append("code", otp);
    reqData.append("verify", 1);
    reqData.append("secret", secret);

    try {
      const { data, status } = await axiosInstance.post(
        "api/twofaverify",
        reqData
      );
      if (status === 200) {
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        setSession(data.access_token);
        navigate(0);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
      Object.entries(err.errors).flatMap(([ke, val]) => {
        setTfaError(val[0]);
      });
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <AuthHelper />
          <ErrorBanner />
        </Stack>

        <Box>
          <Stack spacing={2}>
            <RHFTextField name="email" label="Email or Username" />
            <Password
              name="password"
              label="Password"
              InputLabelProps={{
                style: { color: "#b4c1ce" },
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label="Remember me" />
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATH_AUTH.resetPassword}
            >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            name="login"
          >
            Login
          </LoadingButton>
        </Box>
      </FormProvider>
      <Dialog open={openOtp}>
        <DialogContent>
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton variant="contained" onClick={handleClose}>
              <Iconify icon="carbon:close-filled" />
            </IconButton>
          </Box>
          <Typography variant="subtitle2" sx={{ mb: 2, mt: 2 }}>
            Two-Factor Authentication (2FA)
          </Typography>
          <Stack spacing={3}>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              label="Enter OTP"
              error={Boolean(tfaError)}
              helperText={tfaError}
            />

            <Button variant="contained" onClick={TfaLogin}>
              Submit
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginForm;
