import { IconButton, TableCell, TableRow } from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import Iconify from "src/components/Iconify";

export const filterType = (type) => {
  switch (snakeCase(type)) {
    case "binary_bonus":
      return "Binary Bonus";
    case "daily_bonus":
      return "Daily Profit";
    case "ambassidor_direct_bonus":
      return "Direct Bonus";
    case "ambassidor_indirect_bonus":
      return "Indirect Bonus";

    default:
      return type;
  }
};
const DataList = ({ income, rowNumber }) => {
  const { id, row } = income;

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        {rowNumber}
      </TableCell>
      <TableCell>{income?.user?.username}</TableCell>
      <TableCell>{capitalCase(filterType(income?.payment_type))}</TableCell>
      <TableCell>{income?.total_amount}</TableCell>
      <TableCell>
        {new Date(income?.created_at).toLocaleDateString("en-GB")}
      </TableCell>
    </TableRow>
  );
};

export default DataList;
