import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import Iconify from "src/components/Iconify";
const DataList = ({ documents, handleOpenMenu, rowNumber }) => {
  const { id, title,document_url: doc_url, sort_order, created_at } = documents;
  return (
    <>
      <TableRow key={id}>
        <TableCell>{rowNumber}</TableCell>
        <TableCell
          component="a"
          href={doc_url}
          target="_blank"
          sx={{
            textDecoration: "none",
          }}
        >
          PDF <Iconify icon={"carbon:download"} />
        </TableCell>
        <TableCell>
          {new Date(created_at).toLocaleDateString("en-GB")} -
          {new Date(created_at).toLocaleTimeString()}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpenMenu(id)} name="more-button">
            <Iconify
              icon={"eva:more-vertical-fill"}
              width={20}
              height={20}
              name="more-button"
            />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DataList;
