import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, sx, InputLabelProps, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          InputLabelProps={{ ...InputLabelProps, style: { color: "#b4c1ce" } }}
        />
      )}
    />
  );
}
