import React, { useEffect, useState } from "react";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import usePagination from "src/components/pagination/usePagination";
import axiosInstance from "src/utils/axios";

const useGetSubChapter = () => {
  const [state, actions] = useDataHandler();
  const { count, onChange, page, seed, rowStart } = usePagination();
  const fetchSubChapter = async (page = 1) => {
    try {
      const { data, status } = await axiosInstance("/api/admin/sub-chapter", {
        params: { page },
      });
      if (status === 200) {
        const { last_page, from, data: list } = data.data;
        seed(last_page, from);
        actions.success(list);
        return;
      }
      actions.success();
    } catch (err) {
      actions.error();
      console.log(err);
    }
  };
  useEffect(() => {
    actions.loading();
    fetchSubChapter(page);
  }, [page]);
  return { state, fetchSubChapter, count, onChange, page, rowStart };
};

export default useGetSubChapter;
