import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  sub_chapter_id: yup.string().required("Sub chapter is required"),
  document_url: yup
    .mixed()
    .test("isFile", "Document is required", (value) => Boolean(value.length)),
});

export const defaultValues = {
  sub_chapter_id: "",
  document_url: "",
};

const useDocumentForm = () => {
  return useForm({
    defaultValues,
    resolver: yupResolver(ValidationSchema),
  });
};

export default useDocumentForm;
