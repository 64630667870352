import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { UrlSchema } from "src/pages/admin/store/material/materialAdd/hooks/useMaterialForm";
import axiosInstance from "src/utils/axios";

import * as yup from "yup";

const Validator = yup.object().shape({
  sub_chapter_id: yup.string().required("Sub chapter is required"),
  url: yup.string().required("Video url is required"),
});

const defaultValues = {
  url: "",
  sub_chapter_id: "",
};
const getRequestData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return formData;
};

const useAddVideo = (cb = () => null) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(Validator),
  });

  const onSubmit = async (reqData) => {
    try {
      const { data } = await axiosInstance.post(
        `api/admin/sub-chapter-video`,
        getRequestData(reqData)
      );
      if (data.status) {
        enqueueSnackbar(data.message);
        methods.reset();
        cb();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to add video", { variant: "error" });
    }
  };

  return { methods, onSubmit: methods.handleSubmit(onSubmit) };
};

export default useAddVideo;
