import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import useAddVideo from "./hooks/useAddVideo";
import { LoadingButton } from "@mui/lab";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useGetSubChapter from "../../../sub-chapter/hook/useGetSubChapter";

const AddDialog = ({ fetchVideo }) => {
  const { translate } = useLocales();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { methods, onSubmit } = useAddVideo(() => {
    fetchVideo();
    handleClose();
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { state } = useGetSubChapter();
  const { data } = state;
  return (
    <div>
      <Button
        variant="contained"
        size="small"
        startIcon={<Iconify icon={"carbon:add"} />}
        onClick={handleClickOpen}
        name="add"
      >
        {translate("adminTools.videos.addVideo")}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="tool-video"
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle id="add-article-category">Add Video</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  marginTop: 3,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <RHFTextField name="url" label="Video URL" />
                <RHFSelect name="sub_chapter_id" label="Sub Chapter">
                  <option value="" />
                  {data.map(({ id, name }) => (
                    <option value={id}> {name}</option>
                  ))}
                </RHFSelect>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton type="submit" variant="contained" name="submit">
              {translate("adminCommunication.articile.submit")}
            </LoadingButton>
            <Button onClick={handleClose} autoFocus color="error" name="close">
              {translate("adminCommunication.articile.close")}
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
};

export default AddDialog;
